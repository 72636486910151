import React, { Suspense, ComponentType } from 'react';
import { useRoutes } from 'react-router-dom';

// Componente de carga (puede personalizar esto según sea necesario)
const loading = () => <div>Cargando...</div>;

// Lazy load de las vistas necesarias
const Saas = React.lazy(() => import('../pages/landings/Saas/Saas'));
const Demos = React.lazy(() => import('../pages/landings/Demos/Demos'));

type LoadComponentProps = {
    component: ComponentType<any>;  // Acepta cualquier componente de React
};

// Componente auxiliar para cargar componentes con Suspense
const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            path: '/',
            element: <LoadComponent component={Saas} /> // Ruta para la landing específica en la raíz
        },
        {
            path: '/demos',
            element: <LoadComponent component={Demos} /> // Ruta para la landing específica en la raíz
        }
    ]);
};

export default AllRoutes;
